import React, { useState, useEffect } from "react";
import { PageHeader, Form, Divider, message, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import useGetPlatform from "../../hooks/useGetPlatform";
import useFetchProvinces from "../../hooks/geo/useFetchProvinces";
import useFetchCities from "../../hooks/geo/useFetchCities";
import useFetchBarangays from "../../hooks/geo/useFetchBarangay";
import useOnChangeCity from "../../hooks/warehouses/useOnChangeCity";
import useOnChangeProvince from "../../hooks/warehouses/useOnChangeProvince";
import useOnChangeBarangay from "../../hooks/warehouses/useOnChangeBarangay";
import useFetchWarehouses from "../../hooks/warehouses/useFetchWarehouses";
import useFetchProviders from "../../hooks/installment/useFetchProviders";
import useGetPermissions from "../../hooks/warehouses/useGetPermissions";
import useFetchAdsWithPhotosPerWarehouse from "../../hooks/warehouses/useFetchAdsWithPhotosPerWarehouse";
import useUnitsWithoutPhoto from "../../hooks/warehouses/useUnitsWithoutPhoto";

import { FetchWarehousesList } from "../../api/warehouse/FetchWarehousesList";
import { FetchWarehouseSpecialistsList } from "../../api/warehouse/FetchWarehouseSpecialistsList";
import { UpdateWarehouseSpecialist } from "../../api/warehouse/UpdateWarehouseSpecialist";
import { DeleteWarehouse } from "../../api/warehouse/DeleteWarehouse";
import { FetchBankList } from "../../api/banks/FetchBankList";
import { GetAdsWithoutPhoto } from "../../api/ads/GetAdsWithoutPhoto";
import { ArchiveWarehouseInformation } from "../../api/warehouse/ArchiveWarehouseInformation";
import { UpdateWarehouseInformation } from "../../api/warehouse/UpdateWarehouseInformation";
import { SendEmailWarehouse } from "../../api/warehouse/SendEmailWarehouse";
import { FetchCities } from "../../api/cities/FetchCities";

import { WarehouseTableComponent } from "../sub_components/warehouse/WarehouseTableComponent";
import { WarehouseFunctionComponent } from "../sub_components/warehouse/WarehouseFunctionsComponent";
import { WarehouseCreationComponent } from "../sub_components/warehouse/WarehouseCreationComponent";
import { WarehouseUpdateComponent } from "../sub_components/warehouse/WarehouseUpdateComponent";
import { WarehouseUpdateAdvisersComponent } from "../sub_components/warehouse/WarehouseUpdateAdvisersComponent";
import { WarehouseUnitsWithoutPhotoModalComponent } from "../sub_components/warehouse/WarehouseUnitsWithoutPhotoModalComponent";
import { BreadCrumbsComponent } from "../BreadCrumbsComponent";
import { WarehouseFiltersAndSelectionComponent } from "../sub_components/warehouse/WarehouseFiltersAndSelectionComponent";
import { ModalwithFormComponent } from "../ModalwithFormComponent";

import "../../dist/style/warehouse_component.css";
import { FormRules } from "../../dist/functions/formRules";
import WarehouseUnitsPhotoStatsModalComponent from "../sub_components/warehouse/WarehouseUnitsPhotoStatsModalComponent";
import useFetchAdsWithoutPhotosPerWarehouse from "../../hooks/warehouses/useFetchAdsWithoutPhotosPerWarehouse";
import useUnitsWithPhoto from "../../hooks/warehouses/useUnitsWithPhoto";

const { confirm } = Modal;

const initialFilteredDataSet = {
  isFiltered: false,
  search_value: null,
  data: { data: [], total: 0 },
};

export const WarehouseManagementComponent = () => {
  const [form] = Form.useForm();
  const [adviserForm] = Form.useForm();
  const currentPlatform = useGetPlatform();
  const [updateWarehouseForm] = Form.useForm();
  const [oldCities, setOldCities] = useState([]);
  const [isShowRemarksModal, setIShowRemarksModal] = useState(false);
  const [specialists, setSpecialists] = useState({ data: [], total: 0 });
  const [banks, setBanks] = useState({ data: [], total: 0 });
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [showWarehouseCreationModal, setShowWarehouseCreationModal] = useState(false);
  const [updateModalVisibility, setUpdateModalVisibility] = useState(false);
  const [updateAdvisersModalVisibility, setUpdateAdvisersModalVisibility] = useState(false);
  const [scheduleUpdateModalVisibility, setScheduleUpdateModalVisibility] = useState(false);
  const [currentPagination, setCurrentPagination] = useState(1);

  const [filteredDataSet, setFilteredDataSet] = useState(initialFilteredDataSet);
  const [isShowColumnFilter, setIsShowColumnFilter] = useState(false);
  const [columnFilterTags, setColumnFilterTags] = useState([]);

  const [columnFilter, setColumnFilter] = useState([]);

  // Custom Hooks:

  const { providers, isProvidersFetchingLoading } = useFetchProviders();
  const { cityId, onChangeCity } = useOnChangeCity();
  const { provinceId, onChangeProvince } = useOnChangeProvince();
  const { onChangeBarangay } = useOnChangeBarangay();
  const { provinces, isProvinceFetchingLoading } = useFetchProvinces();
  const { cities, isCityFetchingLoading } = useFetchCities(provinceId);
  const { barangays, isBarangayFetchLoading } = useFetchBarangays(cityId);
  const {
    warehouses,
    warehouseTableMeta,
    warehouseTableLoading,
    onPaginationChange,
    onWarehouseSearch,
  } = useFetchWarehouses();
  const userPermissions = useGetPermissions();
  const { adsWithPhotos, fetchAdsWithPhotos } = useFetchAdsWithPhotosPerWarehouse();
  const { adsWithoutPhotos, fetchAdsWithoutPhotos } = useFetchAdsWithoutPhotosPerWarehouse();
  const {
    isUnitsWithoutPhotoModalVisible,
    toggleUnitsWithoutPhotoModal,
    warehouseLocationWithoutPhoto,
    closeUnitsWithoutPhotoModal,
  } = useUnitsWithoutPhoto(fetchAdsWithoutPhotos);
  const {
    closeUnitsWithPhotoModal,
    isUnitsWithPhotoModalVisible,
    warehouseLocationWithPhoto,
    toggleUnitsWithPhotoModal,
  } = useUnitsWithPhoto(fetchAdsWithPhotos);

  const handleseShowRemarksModal = async () => {
    setIShowRemarksModal(!isShowRemarksModal);
  };

  const GetOldCities = async () => {
    const data = await FetchCities();
    setOldCities(data);
  };

  const handleUpdateModalVisibility = async () => {
    const status = !updateModalVisibility;
    setUpdateModalVisibility(status);

    if (status) {
      const {
        landmark,
        viewing_address,
        direction_here,
        bank_id,
        city_id,
        city__id,
        days_avail,
        hours_open,
        barangay_id,
        province_id,
        location,
        provider_id,
      } = selectedWarehouse;

      onChangeProvince(province_id, provinces);
      onChangeCity(city__id, cities);
      onChangeBarangay(barangay_id, barangays);

      const specialists_arr = await FetchWarehouseSpecialistsDataByMaxId();

      updateWarehouseForm.setFieldsValue({
        viewing_address,
        landmark,
        direction_here,
        bank_id,
        address: {
          barangay_id,
          city_id,
          province_id,
          city__id, // for depreciation, but transfer data into city_id
          location, // for depreciation
        },
        provider_id,
        days_avail,
        hours_open,
        specialists: specialists_arr,
      });
    }
  };

  const handleUpdateAdviserModalVisibility = async () => {
    const status = !updateAdvisersModalVisibility;
    setUpdateAdvisersModalVisibility(status);

    if (status) {
      const specialists_arr = await FetchWarehouseSpecialistsDataByMaxId();
      adviserForm.setFieldsValue({
        specialists: specialists_arr,
      });
    }
  };

  const FetchWarehouseSpecialistsDataByMaxId = async () => {
    const specialists_arr = [];
    for (const specialist of selectedWarehouse.specialists) {
      specialists_arr.push(specialist.specialist_id);
    }

    const specialistMaxId = Math.max(...specialists_arr);
    const maxId = specialistMaxId < 10 ? 10 : specialistMaxId;
    await FetchWarehouseSpecialists(1, maxId);

    adviserForm.setFieldsValue({
      specialists: specialists_arr,
    });

    return specialists_arr;
  };

  const handleUpdateScheduleModalVisibility = () => {
    setScheduleUpdateModalVisibility(!scheduleUpdateModalVisibility);
  };

  const onChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
    setSelectedWarehouse(null);
    setSelectedWarehouse(selectedRows[0]);
  };

  const onClearSelection = () => {
    setSelectedRowKeys([]);
    setSelectedWarehouse(null);
    setFilteredDataSet(initialFilteredDataSet);
  };

  const FetchWarehouseSpecialists = async (page = 1, limit = 10) => {
    const data = await FetchWarehouseSpecialistsList({
      page: page,
      limit: limit,
    });

    setSpecialists(data);
  };

  const FetchBanks = async (page = 1, limit = 10) => {
    const query = { page: page, limit: limit };
    const data = await FetchBankList(query);
    setBanks(data);
  };

  const HandleUpdateWarehouseAdvisers = async () => {
    const values = await adviserForm.validateFields();
    const specialist_arr = [];
    for (const specialist of values.specialists) {
      specialist_arr.push({
        specialist_id: specialist,
      });
    }

    values.specialists = specialist_arr;
    await UpdateWarehouseSpecialist(selectedRowKeys, values);
    message.success("Successfuly Updated Warehouse Adviser", 2);
    handleUpdateAdviserModalVisibility();
    adviserForm.resetFields();
    onClearSelection();
    setCurrentPagination(1);
  };

  const OnClickDeleteWarehouse = () => {
    confirm({
      title: `Are you sure, you want to remove this warehouse?`,
      icon: <ExclamationCircleOutlined />,
      content: `All information attached to this warehouse will be removed`,
      async onOk() {
        message.loading("Deleting Warehouse Information..", 2.5).then(async () => {
          await DeleteWarehouse(selectedRowKeys);
          message.success("Successfuly Deleted Warehouse", 2).then(() => {
            onClearSelection();
          });
        });
      },
      onCancel() {},
    });
  };

  const onClearColumnFilters = () => {
    setColumnFilterTags([]);
    setIsShowColumnFilter(false);
    setColumnFilter([]);
  };

  useEffect(() => {
    FetchWarehouseSpecialists();
    FetchBanks();
    GetOldCities();
  }, []);

  const onClickShowMoreBanks = async () => {
    const { page, lastPage } = banks;
    if (page < lastPage) {
      const query = { page: parseInt(page) + 1, limit: 10 };
      const data = await FetchBankList(query);

      const oldBanks = banks.data;
      for (const item of data.data) {
        oldBanks.push(item);
      }

      const newState = {
        ...data,
        data: oldBanks,
      };

      setBanks(newState);
      return;
    }
  };

  const onClickShowMoreAdviser = async () => {
    const { page, lastPage, perPage } = specialists;
    if (page < lastPage) {
      const query = { page: parseInt(page) + 1, limit: perPage };
      const data = await FetchWarehouseSpecialistsList(query);
      const oldSpecialists = specialists.data;
      for (const item of data.data) {
        oldSpecialists.push(item);
      }

      const newState = {
        ...data,
        data: oldSpecialists,
      };

      setSpecialists(newState);
      return;
    }
  };

  const showConfirm = (value, record, switchValue) => {
    confirm({
      title: `Do you want to update financing status of this warehouse - ${record.location}?`,
      icon: <ExclamationCircleOutlined />,
      content: `You are going to update the financing status of this warehouse`,
      async onOk() {
        const payload = { has_financing: switchValue ? 1 : 0 };
        message.loading("Updating Warehouse Financing Status..", 2.5).then(async () => {
          await UpdateWarehouseInformation(payload, record.id);
          message.success("Successfuly Updated Warehouse Financing Status", 2);
        });
      },
      onCancel() {},
    });
  };

  const OnChangeFinancingStatus = async (active_status, record, switchValue) => {
    if (active_status !== switchValue) {
      showConfirm(active_status, record, switchValue);
    }
  };

  const onRemoveAllSearches = () => {
    adviserForm.resetFields();
    setSelectedRowKeys(null);
    setSelectedWarehouse(null);
    onClearSelection();
    form.resetFields();
  };

  const OnUpdateStatus = async () => {
    await message.info("To proceed kindly provide reason for archiving", 3);
    handleseShowRemarksModal();
  };

  const onClickArchiveReason = async () => {
    const values = await adviserForm.validateFields();
    await message.loading("Updating Status...", 2);
    const reason_for_archiving = values.reason_for_archiving;
    const payload = { is_active: 0, reason_for_archiving };

    const data = await ArchiveWarehouseInformation(payload, selectedRowKeys[0]);

    if (data.status === 200) {
      onRemoveAllSearches();
      message.success("Successfuly Updated Status");
      await FetchWarehousesList();

      handleseShowRemarksModal();

      return;
    }
    message.error("Error updating status");
    message.info("Please seek help from support");
  };

  const formInputsComponent = [
    {
      name: "reason_for_archiving",
      label: "Reason For Archiving",
      default_value: "",
      configs: {
        type: "text_area",
        rules: [FormRules.REQUIRED_ARCHIVING_REASON],
        text_area_row: 5,
      },
    },
  ];

  return (
    <>
      <PageHeader
        title="WAREHOUSE"
        subTitle="WAREHOUSE MANAGEMENT VIEWING/SCHEDULING"
        breadcrumbRender={() => <BreadCrumbsComponent breadcrumbsItem={["Warehouses"]} />}
      />
      <div className="content-pane-transaction-div-style">
        <Divider />
        <WarehouseFunctionComponent
          OnUpdateStatus={OnUpdateStatus}
          selectedWarehouse={selectedWarehouse}
          userPermissions={userPermissions}
          handleUpdateModalVisibility={handleUpdateModalVisibility}
          OnClickDeleteWarehouse={OnClickDeleteWarehouse}
          setShowWarehouseCreationModal={setShowWarehouseCreationModal}
          handleUpdateAdviserModalVisibility={handleUpdateAdviserModalVisibility}
          handleUpdateScheduleModalVisibility={handleUpdateScheduleModalVisibility}
          reportsData={warehouses}
          onSearch={onWarehouseSearch}
        />
        <WarehouseFiltersAndSelectionComponent
          onClearSearcheAndSelection={onClearSelection}
          selectedWarehouse={selectedWarehouse}
          filteredDataSet={filteredDataSet}
          isShowColumnFilterTags={isShowColumnFilter}
          columnFilterTags={columnFilterTags}
          onClearColumnFilters={onClearColumnFilters}
        />
        <WarehouseTableComponent
          tableLoading={warehouseTableLoading}
          data={warehouses}
          onChange={onChange}
          meta={warehouseTableMeta}
          selectedRowKeys={selectedRowKeys}
          onPaginationChange={onPaginationChange}
          onClickViewUnitsWithoutPhoto={toggleUnitsWithoutPhotoModal}
          onClickViewUnitsWithPhoto={toggleUnitsWithPhotoModal}
          adsWithPhotos={adsWithPhotos}
          currentPagination={currentPagination}
          filteredDataSet={filteredDataSet}
          platform={currentPlatform}
          onChangeFinancingStatus={OnChangeFinancingStatus}
          columnFilter={columnFilter}
          setColumnFilter={setColumnFilter}
        />
      </div>
      <ModalwithFormComponent
        title={"INPUT REASON FOR CANCELLING"}
        form={adviserForm}
        formLayout={"vertical"}
        formInputsComponent={formInputsComponent}
        onOk={onClickArchiveReason}
        onCancel={handleseShowRemarksModal}
        destroyOnClose={true}
        okText={"Save Remarks"}
        cancelText={"Cancel"}
        visible={isShowRemarksModal}
      />
      <WarehouseCreationComponent
        showWarehouseCreationModal={showWarehouseCreationModal}
        setShowWarehouseCreationModal={setShowWarehouseCreationModal}
        specialists={specialists}
        banks={banks}
        onClickShowMoreBanks={onClickShowMoreBanks}
        onClickShowMoreAdviser={onClickShowMoreAdviser}
        onChangeCity={onChangeCity}
        onChangeProvince={onChangeProvince}
        onChangeBarangay={onChangeBarangay}
        provinces={provinces}
        providers={providers}
        isProvidersFetchingLoading={isProvidersFetchingLoading}
        isProvinceFetchingLoading={isProvinceFetchingLoading}
        cities={cities}
        oldCities={oldCities}
        isCityFetchingLoading={isCityFetchingLoading}
        barangays={barangays}
        isBarangayFetchLoading={isBarangayFetchLoading}
      />
      <WarehouseUpdateComponent
        form={updateWarehouseForm}
        warehouseId={selectedRowKeys}
        updateModalVisibility={updateModalVisibility}
        handleUpdateModalVisibility={handleUpdateModalVisibility}
        selectedWarehouse={selectedWarehouse}
        specialists={specialists}
        banks={banks}
        onClickShowMoreBanks={onClickShowMoreBanks}
        setCurrentPagination={setCurrentPagination}
        onClearSelection={onClearSelection}
        onChangeCity={onChangeCity}
        onChangeProvince={onChangeProvince}
        onChangeBarangay={onChangeBarangay}
        provinces={provinces}
        providers={providers}
        isProvidersFetchingLoading={isProvidersFetchingLoading}
        isProvinceFetchingLoading={isProvinceFetchingLoading}
        cities={cities}
        oldCities={oldCities}
        isCityFetchingLoading={isCityFetchingLoading}
        barangays={barangays}
        isBarangayFetchLoading={isBarangayFetchLoading}
      />
      {selectedWarehouse && (
        <WarehouseUpdateAdvisersComponent
          specialists={specialists}
          updateAdvisersModalVisibility={updateAdvisersModalVisibility}
          handleUpdateAdviserModalVisibility={handleUpdateAdviserModalVisibility}
          HandleUpdateWarehouseAdvisers={HandleUpdateWarehouseAdvisers}
          form={adviserForm}
          FetchWarehouseSpecialists={FetchWarehouseSpecialists}
          onClickShowMoreAdviser={onClickShowMoreAdviser}
        />
      )}
      <WarehouseUnitsPhotoStatsModalComponent
        title={"Units With Photo"}
        visible={isUnitsWithPhotoModalVisible}
        data={adsWithPhotos}
        onClose={closeUnitsWithPhotoModal}
        location={warehouseLocationWithPhoto}
      />
      <WarehouseUnitsPhotoStatsModalComponent
        title={"Units Without Photo"}
        visible={isUnitsWithoutPhotoModalVisible}
        data={adsWithoutPhotos}
        onClose={closeUnitsWithoutPhotoModal}
        location={warehouseLocationWithoutPhoto}
      />
    </>
  );
};
