import React from "react";
import { Divider, Table, Tag, Switch } from "antd";
import { CompassFilled } from "@ant-design/icons";
import { WarehouseSpecialistsComponent } from "./WarehouseSpecialistsComponent";
import { WarehouseAdsInformationComponent } from "./WarehouseAdsInformationComponent";

const { Column } = Table;
export const WarehouseTableComponent = ({
  data,
  onChange,
  tableLoading,
  meta,
  selectedRowKeys,
  onPaginationChange,
  onClickViewUnitsWithoutPhoto,
  onClickViewUnitsWithPhoto,
  onChangeFinancingStatus,
  adsWithPhotos,
}) => {
  return (
    <>
      <Table
        loading={tableLoading}
        size={"small"}
        dataSource={data}
        bordered={true}
        rowSelection={{
          selectedRowKeys,
          type: "radio",
          onChange: onChange,
        }}
        pagination={{
          total: meta?.total,
          onChange: onPaginationChange,
          current: meta?.page,
        }}
        scroll={{ x: 1550 }}
        expandable={{
          expandedRowRender: (record) => (
            <div style={{ padding: "3%" }}>
              <WarehouseAdsInformationComponent
                total_items={record.total_items}
                total_buy_now_units={record.total_buy_now_units}
                units_with_photo={record.units_with_photo}
                units_without_photo={record.units_without_photo}
                total_active_bids={record.total_active_bids}
                location={record.location}
                onClickViewUnitsWithoutPhoto={onClickViewUnitsWithoutPhoto}
                onClickViewUnitsWithPhoto={onClickViewUnitsWithPhoto}
                adsWithPhotos={adsWithPhotos}
                warehouse_id={record.id}
              />
              <br />
              <WarehouseSpecialistsComponent specialists={record.specialists} />
            </div>
          ),
        }}
      >
        <Column title="Id" dataIndex="id" key="id" />
        <Column
          title="Viewing Address"
          dataIndex="viewing_address"
          key="viewing_address"
        />
        <Column
          title="Barangay"
          dataIndex={["barangay", "barangay_name"]}
          key={["barangay", "barangay_name"]}
        />
        <Column
          title="City"
          dataIndex={["city", "city_name"]}
          key={["city", "city_name"]}
        />
        <Column
          title="Province"
          dataIndex={["province", "province_name"]}
          key={["province", "province_name"]}
        />
        <Column title="Landmark" dataIndex="landmark" key="landmark" />

        <Column
          title="Google Map Uri"
          dataIndex="direction_here"
          key="direction_here"
          width={"10%"}
          render={(direction_here) => (
            <Tag
              className="warehouse-google-map-tag"
              icon={<CompassFilled color="red" />}
              color="green"
              style={{ color: "darkgoldenrod" }}
              href={direction_here}
              onClick={() => {
                window.open(direction_here);
              }}
            >
              Google Maps
            </Tag>
          )}
        />
        <Column title="Days Open" dataIndex="days_open" key="days_open" />
        <Column title="Hours Open" dataIndex="hours_open" key="hours_open" />
        <Column
          title="Bank"
          dataIndex={["bank", "name"]}
          key={["bank", "name"]}
        />
        <Column
          title="Provider"
          dataIndex={["provider", "provider_name"]}
          key={["provider", "provider_name"]}
        />
        <Column
          title="Has Financing?"
          dataIndex="has_financing"
          key="has_financing"
          render={(has_financing, record) => (
            <Switch
              className="warehouse-financing-switch"
              checkedChildren="Yes"
              unCheckedChildren="No"
              defaultChecked={has_financing === 1 ? true : false}
              onChange={(switchValue) =>
                onChangeFinancingStatus(has_financing, record, switchValue)
              }
            />
          )}
        />
        <Column title="Date Created" dataIndex="created_at" key="created_at" />
      </Table>
    </>
  );
};
