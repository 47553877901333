import React, { useState } from "react";
import { PageHeader, Divider, Form, message } from "antd";
import { BidsTableComponent } from "../sub_components/bids/BidsTableListComponent";
import { BidsFunctionalityComponent } from "../sub_components/bids/BidsFunctionalityComponent";
import { BuyNowFiltersAndSelectionsComponent } from "../sub_components/buy_now/BuyNowFiltersAndSelectionsComponent";
import { BidsSearchFunctionalityComponent } from "../sub_components/bids/BidsSearchFunctionalityComponent";
import { BreadCrumbsComponent } from "../BreadCrumbsComponent";
import { ModalwithFormComponent } from "../ModalwithFormComponent";
import { FormRules } from "../../dist/functions/formRules";
import returnLastWednesdayDate from "../../dist/functions/returnLastWednesdayDate";
import moment from "moment";
import useGetPlatform from "../../hooks/useGetPlatform";
import useIsMobile from "../../hooks/useIsMobile";
import useFetchBids from "../../hooks/auctions/useFetchBids";
import useGetBiddingReportsPermission from "../../hooks/auctions/useGetBiddingReportsPermission";
import useManipulateFilterTags from "../../hooks/auctions/useManipulateFilterTags";
import useSort from "../../hooks/global/useSort";
import useUpdateBid from "../../hooks/auctions/useUpdateBid";

export const BiddingWinnersManagementComponent = () => {
  const [form] = Form.useForm();
  const [remarksForm] = Form.useForm();
  const [data, setData] = useState([]);
  const [reportDateRange, setReportDateRange] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isUpdateButtonHidden, setIsUpdateButtonHidden] = useState(true);
  const [searchedKeyword, setSearchedKeyword] = useState(null);
  const [keywordType, setKeywordType] = useState(null);
  const [isShowRemarksModal, setIShowRemarksModal] = useState(false);
  const [isShowBidsFilterModal, setIsBidsFilterModal] = useState(false);
  const [currentPagination, setCurrentPagination] = useState(1);
  const [currentCount, setCurrentCount] = useState(10);

  const currentPlatform = useGetPlatform();
  const isMobile = useIsMobile();
  const bidReportsPermissions = useGetBiddingReportsPermission();
  const { bids, fetchBids, loading, error, clearGeneratedData } = useFetchBids();
  const {
    isShowColumnFilter,
    onClearColumnFilters,
    columnFilter,
    columnFilterFrontend,
    columnFilterTags,
  } = useManipulateFilterTags();
  const { setSortColumn, setSortType, sortColumn, sortType } = useSort();
  const { updateBid } = useUpdateBid();

  const onSortChange = async (sortCol, sortType) => {
    setSortColumn(sortCol);
    setSortType(sortType);
    await onSubmitSearch(currentPagination, currentCount);
  };

  const handleseShowRemarksModal = async () => {
    setIShowRemarksModal(!isShowRemarksModal);
  };

  const handleShowFilterBidsModal = () => {
    setIsBidsFilterModal(!isShowBidsFilterModal);
  };

  const onSubmitSearch = async (page, limit) => {
    setCurrentPagination(page);

    const values = await form.validateFields();
    const { report_date_range, keyword, type, isIncludePreviousBids } = values;

    const date_from = report_date_range
      ? report_date_range[0].format("YYYY-MM-DD HH:mm:ss")
      : returnLastWednesdayDate();

    const date_to = report_date_range
      ? report_date_range[1].format("YYYY-MM-DD HH:mm:ss")
      : moment().format("YYYY-MM-DD HH:mm:ss");

    const keyword_type = keyword ? type : "none";
    const search_value = keyword ?? "none";

    await fetchBids({
      page,
      limit,
      filter_type: "active",
      date_from,
      date_to,
      keyword_type,
      search_value,
      sortColumn,
      sortType: sortType.current,
      columnFilter,
      is_include_previous_bids: isIncludePreviousBids,
    });

    setReportDateRange(`${date_from}-${date_to}`);

    if (keyword) {
      setKeywordType(keyword_type);
      setSearchedKeyword(search_value);
    }

    handleShowFilterBidsModal();
  };

  const onRemoveSelection = () => {
    setSelectedRowKeys(null);
    setSelectedRow(null);
    setIsUpdateButtonHidden(true);
  };

  const onRemoveAllSearches = () => {
    form.resetFields();
    clearGeneratedData();
    setSearchedKeyword(null);
    setKeywordType(null);
    onRemoveSelection();
    setReportDateRange(null);
  };

  const onPaginationChange = async (value, currentCount) => {
    setCurrentCount(currentCount);
    setCurrentPagination(value);
    onSubmitSearch(value, currentCount);
  };

  const onSelectRowChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
    setSelectedRow(selectedRows[0]);
    setIsUpdateButtonHidden(false);
  };

  const OnUpdateStatus = async () => {
    await message.info("To proceed kindly provide reason for cancelling", 4);
    handleseShowRemarksModal();
  };

  const handleBidCancellation = async () => {
    await remarksForm.validateFields();

    const payload = { is_accepted: 2 };
    const response = await updateBid(selectedRowKeys, payload);

    if (response) {
      onRemoveAllSearches();
      await fetchBids();
      handleseShowRemarksModal();
    }
  };

  const formInputsComponent = [
    {
      name: "reason_for_cancellation",
      label: "Reason For Cancellation",
      defaultValue: "",
      configs: {
        type: "text_area",
        rules: [FormRules.REQUIRED_CANCELLATION_REASON],
        textAreaRow: 5,
      },
    },
  ];

  return (
    <>
      <PageHeader
        title="WINNING BIDDERS"
        subTitle="WINNING BIDDERS REPORT MANAGEMENT"
        breadcrumbRender={() => <BreadCrumbsComponent breadcrumbsItem={["Auctions", "Bidders"]} />}
      />

      <div className="content-pane-transaction-div-style">
        <Divider />
        <div style={{ textAlign: isMobile ? "center" : "left" }}>
          <BidsSearchFunctionalityComponent
            onSubmitSearch={onSubmitSearch}
            visible={isShowBidsFilterModal}
            onCancel={handleShowFilterBidsModal}
            form={form}
          />
        </div>
        <div style={{ textAlign: isMobile ? "center" : "right" }}>
          <BidsFunctionalityComponent
            isUpdateButtonHidden={isUpdateButtonHidden}
            OnUpdateStatus={OnUpdateStatus}
            data={bids}
            bidReportsPermissions={bidReportsPermissions}
            platform={currentPlatform}
            onClickFilterBids={handleShowFilterBidsModal}
          />
        </div>
        <BuyNowFiltersAndSelectionsComponent
          onRemoveAllSearches={onRemoveAllSearches}
          onRemoveSelection={onRemoveSelection}
          reportDateRange={reportDateRange}
          selectedRow={selectedRow}
          searchKeyword={searchedKeyword}
          keywordType={keywordType}
          onClearColumnFilters={onClearColumnFilters}
          isShowColumnFilterTags={isShowColumnFilter}
          columnFilterTags={columnFilterTags}
        />
        <div style={{ marginTop: "2%" }}>
          <BidsTableComponent
            data={bids}
            loading={loading}
            error={error}
            tableLoading={loading}
            onPaginationChange={onPaginationChange}
            onSelectRowChange={onSelectRowChange}
            selectedRowKeys={selectedRowKeys}
            columnFilter={columnFilter}
            onSortChange={onSortChange}
            columnFilterFrontend={columnFilterFrontend}
          />
        </div>
        <ModalwithFormComponent
          title={"INPUT REASON FOR CANCELLING"}
          form={remarksForm}
          formLayout={"vertical"}
          onOk={handleBidCancellation}
          onCancel={handleseShowRemarksModal}
          destroyOnClose={true}
          okText={"Save Remarks"}
          cancelText={"Cancel"}
          visible={isShowRemarksModal}
          formInputsComponent={formInputsComponent}
        />
      </div>
    </>
  );
};
