import React from "react";
import { Card, Statistic, Button } from "antd";
import {
  FileImageOutlined,
  FileUnknownOutlined,
  CheckCircleOutlined,
  CarOutlined,
  DollarOutlined,
  EyeOutlined,
} from "@ant-design/icons";

const gridStyle = {
  width: "20%",
  textAlign: "left",
};

export const WarehouseAdsInformationComponent = ({
  onClickViewUnitsWithoutPhoto,
  onClickViewUnitsWithPhoto,
  location,
  warehouse_id,
  total_items = 0,
  total_buy_now_units = 0,
  units_with_photo = 0,
  units_without_photo = 0,
  total_active_bids = 0,
}) => {
  return (
    <>
      <Card title="Unit(s) Summary" bordered={false} size="small">
        <Card.Grid hoverable={true} key="ads_count" style={gridStyle}>
          <Statistic
            title="Total Items"
            value={total_items}
            valueStyle={{ color: "#2a2d4c" }}
            prefix={<CarOutlined />}
          />
        </Card.Grid>
        <Card.Grid hoverable={true} key="active_biddings" style={gridStyle}>
          <Statistic
            title="Active Biddings"
            value={total_active_bids}
            valueStyle={{ color: "#2a2d4c" }}
            prefix={<DollarOutlined />}
          />
        </Card.Grid>
        <Card.Grid hoverable={true} key="buy_now_units" style={gridStyle}>
          <Statistic
            title="Buy Now Units"
            value={total_buy_now_units}
            valueStyle={{ color: "#2a2d4c" }}
            prefix={<CheckCircleOutlined />}
          />
        </Card.Grid>
        <Card.Grid hoverable={true} key="units_with_photo" style={gridStyle}>
          <Statistic
            title="Units With Photo"
            value={units_with_photo}
            valueStyle={{ color: "#2a2d4c" }}
            prefix={<FileImageOutlined />}
          />
          <Button
            size="small"
            type="outline"
            icon={<EyeOutlined />}
            disabled={
              total_items > 0 ? (units_with_photo > 0 ? false : true) : true
            }
            onClick={() => onClickViewUnitsWithPhoto(warehouse_id, location)}
          >
            View List
          </Button>
        </Card.Grid>
        <Card.Grid hoverable={true} key="units_without_photo" style={gridStyle}>
          <Statistic
            title="Units Without Photo"
            value={units_without_photo}
            valueStyle={{ color: "#2a2d4c" }}
            prefix={<FileUnknownOutlined />}
          />
          <Button
            size="small"
            type="outline"
            icon={<EyeOutlined />}
            disabled={
              total_items > 0 ? (units_without_photo > 0 ? false : true) : true
            }
            onClick={() => onClickViewUnitsWithoutPhoto(warehouse_id, location)}
          >
            View List
          </Button>
        </Card.Grid>
      </Card>
    </>
  );
};
